<template>
  <div class="users-table">
    <vs-table
      :data="audiences"
      pagination
      :max-items="maxItems"
      search
      :no-data-text="lang.audiences.view.table.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th>
          {{ lang.audiences.view.table.name[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.audiences.view.table.platform[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.audiences.view.table.count[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.audiences.view.table.scheduledSend[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.audiences.view.table.parameters[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.audiences.view.table.created[languageSelected] }}
        </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
          <vs-td :data="data[indextr].platform">
            {{ getPlatformName(data[indextr].platform) }}
          </vs-td>
          <vs-td :data="data[indextr]._audienceItems">
            <vs-chip>{{ data[indextr]._audienceItems }}</vs-chip>
          </vs-td>
          <vs-td
            v-if="data[indextr]._push && data[indextr]._push[0]"
            :data="data[indextr]._push[0].schedule"
            class="schedule"
          >
            <feather-icon icon="ClockIcon" svgClasses="h-4 w-4" class="mr-2" />
            {{ data[indextr]._push[0].schedule | moment(dateFormat) }}
          </vs-td>
          <vs-td
            v-if="data[indextr]._push && data[indextr]._push[0]"
            :data="
              data[indextr]._push[0].hsm || data[indextr]._push[0].template
            "
            class="schedule"
          >
            <div v-if="data[indextr]._push[0].hsm">
              <div v-if="data[indextr]._push[0].hsm.elementName">
                <b>elementName</b>:
                {{ data[indextr]._push[0].hsm.elementName }} <br />
              </div>
              <div v-if="data[indextr]._push[0].hsm.namespace">
                <b>namespace</b>: {{ data[indextr]._push[0].hsm.namespace }}
                <br />
              </div>
              <div v-if="data[indextr]._push[0].hsm.campaignAlias">
                <b>campaignAlias</b>:
                {{ data[indextr]._push[0].hsm.campaignAlias }} <br />
              </div>
              <div v-if="data[indextr]._push[0].hsm.parameters">
                <b>parameters</b>: {{ data[indextr]._push[0].hsm.parameters }}
                <br />
              </div>
              <div v-if="data[indextr]._push[0].hsm.languageCode">
                <b>languageCode</b>:
                {{ data[indextr]._push[0].hsm.languageCode }} <br />
              </div>
            </div>
            <div v-if="data[indextr]._push[0].template">
              <div v-if="data[indextr]._push[0].template.elementName">
                <b>elementName</b>:
                {{ data[indextr]._push[0].template.elementName }} <br />
              </div>
              <div v-if="data[indextr]._push[0].template.namespace">
                <b>namespace</b>:
                {{ data[indextr]._push[0].template.namespace }} <br />
              </div>
              <div v-if="data[indextr]._push[0].template.campaignAlias">
                <b>campaignAlias</b>:
                {{ data[indextr]._push[0].template.campaignAlias }} <br />
              </div>
              <div v-if="data[indextr]._push[0].template.bodyParameters">
                <b>bodyParameters</b>:
                {{ data[indextr]._push[0].template.bodyParameters }} <br />
              </div>
              <div v-if="data[indextr]._push[0].template.languageCode">
                <b>languageCode</b>:
                {{ data[indextr]._push[0].template.languageCode }} <br />
              </div>
            </div>
          </vs-td>
          <vs-td :data="data[indextr]._createdAt">
            {{ data[indextr]._createdAt | moment(dateFormat) }}
          </vs-td>
          <vs-td>
            <div class="table-actions">
              <!-- <vs-button
                class="with-space-to-the-right"
                color="warning"
                type="filled"
                icon="edit"
                v-if="onEdit"
                @click="edit(data[indextr]._id)"
              ></vs-button> -->
              <vs-button
                class="with-space-to-the-right"
                color="warning"
                type="flat"
                icon="icon-download"
                size="small"
                icon-pack="feather"
                v-if="onDownload"
                @click="
                  download({
                    id: data[indextr]._id,
                    name: data[indextr].name,
                    platform: getPlatformName(data[indextr].platform)
                  })
                "
              >
                {{
                  lang.audiences.view.table.downloadAudience[languageSelected]
                }}
              </vs-button>
              <vs-button
                color="warning"
                type="flat"
                icon="icon-trash"
                size="small"
                icon-pack="feather"
                @click="remove(data[indextr]._id)"
              >
                {{ lang.audiences.view.table.remove[languageSelected] }}
              </vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { PLATFORMS } from '../../../../constants/constants'

export default {
  name: 'DiffusionTable',
  props: {
    audiences: {
      type: Array
    },
    maxItems: {
      type: Number,
      default: 5
    },
    onDownload: {
      type: Function,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    dateFormat() {
      return this.languageSelected === 'es'
        ? 'DD/MM/YYYY HH:mm' // , h:mm:ss A'
        : 'YYYY/MM/DD HH:mm' // , h:mm:ss A'
    }
  },
  methods: {
    download({ id, name, platform }) {
      this.onDownload({ id, name, platform })
      return
    },
    edit(id) {
      this.onEdit(id)
      return
    },
    remove(id) {
      this.onDelete(id)
      return
    },
    getPlatformName(platform) {
      const platformObj = PLATFORMS.find(p => p.value === platform)
      return platformObj && platformObj.name
    }
  }
}
</script>
<style lang="scss">
.table-actions {
  display: flex;
  justify-content: flex-end;
}
.with-space-to-the-right {
  margin-right: 5px;
}
.schedule {
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
